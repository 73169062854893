import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/ContactForm"

const AboutPage = () => {
  return (
    <Layout>
      <SEO title="About" />
      <h1>About</h1>
      <p>Hi! I'm Will, a software developer from Melbourne, Australia.</p>

      <p>
        I love to create things and solve interesting problems using technology.
        I'll be using this as a space to share my thoughts, ideas and anything I
        build that may be useful or interesting to others.
      </p>

      <h2>Contact</h2>

      <ContactForm />
    </Layout>
  )
}
export default AboutPage
