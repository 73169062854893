import React, { useState } from "react"
import axios from "axios"

const ContactForm = () => {
  const [state, setState] = useState({
    submitted: false,
    fields: {
      message: "",
      email: "",
    },
    errors: {
      message: getError("message", ""),
      email: getError("email", ""),
    },
    showErrors: false,
  })

  const handleChange = e => {
    const { name, value } = e.target
    setState(prevState => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: getError(name, value),
      },
    }))
  }

  const handleSubmit = e => {
    e.preventDefault()

    if (hasErrors(state.errors)) {
      return setState(prevState => ({
        ...prevState,
        showErrors: true,
      }))
    }

    setState(prevState => ({
      ...prevState,
      submitted: "Just one moment...",
    }))

    axios
      .post(
        e.target.action,
        encode({
          "form-name": "contact",
          ...state.fields,
        }),
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      )
      .then(() => {
        setState(prevState => ({
          ...prevState,
          submitted: "Thanks! I'll be in touch!",
        }))
      })
      .catch(_error => {
        setState(prevState => ({
          ...prevState,
          submitted: "Oops! Something went wrong...",
        }))
      })
  }

  return (
    <div className="max-w-lg">
      {state.submitted ? (
        state.submitted
      ) : (
        <form
          name="contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
        >
          <div>
            <label className="flex items-center" htmlFor="email">
              Email:
            </label>

            <input
              className="border-2 mb-3 border-black rounded p-2 min-w-full"
              type="text"
              name="email"
              onChange={handleChange}
              value={state.fields.email}
            />

            {state.showErrors && state.errors.email && (
              <div className="mb-3 text-red font-bold">
                {state.errors.email}
              </div>
            )}
          </div>

          <div>
            <label className="flex items-center" htmlFor="message">
              Message:
            </label>

            <textarea
              className="border-2 mb-3 border-black rounded p-2 min-w-full h-40"
              name="message"
              onChange={handleChange}
              value={state.fields.message}
            />

            {state.showErrors && state.errors.message && (
              <div className="mb-3 text-red font-bold">
                {state.errors.message}
              </div>
            )}
          </div>

          <input type="hidden" name="form-name" value="contact" />

          <button className="bg-secondary self-end text-white rounded text-l px-4 py-2 font-bold">
            Submit
          </button>
        </form>
      )}
    </div>
  )
}

export default ContactForm

function capitalize(str) {
  return str[0].toUpperCase() + str.slice(1, str.length)
}

function getError(name, value) {
  if (!value || value.trim() === "") {
    return capitalize(`${name} is required`)
  }

  if (name === "email") {
    if (!value.match(/.@./)) {
      return `Invalid email`
    }
  }

  return null
}

function hasErrors(obj) {
  return Object.keys(obj).some(key => obj[key] !== null)
}

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}
